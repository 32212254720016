import './App.css'
import MockInterview from './MockInterview'
import { GApiProvider } from 'react-gapi-auth2';
function App() {
  const title = 'House of Product'
  const clientConfig = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    cookie_policy: 'single_host_origin'
  };
  return (
    <div className="App">
      <GApiProvider clientConfig={clientConfig}>
        <header className="App-header">
          {title}
        </header>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@470;500;550;600;700&display=swap" rel="stylesheet" />
        <MockInterview />
      </GApiProvider>
    </div>
  );
}

export default App