import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import { Link, Box, Stack, Typography, FormControlLabel, Checkbox } from '@mui/material';
import "./HomePage.css"
import Swal from 'sweetalert2'
import { privacy_policy } from './privacy_policy';
const clientId = process.env.REACT_APP_CLIENT_ID

/*
/home/?job=<jobID>
<jobID> optional
*/

const HomePage = () => {
    const [candidate, setCandidate] = useState('Candidate');
    const [candidateFirstName, setCandidateFirstName] = useState('Candidate');
    const [candidateLastName, setCandidateLastName] = useState('');
    const [candiateEmail, setCandidateEmail] = useState('');
    const [login, setLogin] = useState(false);
    const [agreeToS, setAgreeToS] = useState(true); // agreed with mirwork privacy policy
    const [jobId, setJobId] = useState(null);
    const [jobIdValid, setJobIdValid] = useState(true);
  
    useEffect(async () => {
      let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
      if (auth2.isSignedIn.get()) {
          let user = auth2.currentUser.get()
          console.log('Sign in with Email: ' +user.getBasicProfile().getEmail())
          setLogin(true)
          setCandidate(user.getBasicProfile().getName())
          setCandidateEmail(user.getBasicProfile().getEmail())
          setCandidateFirstName(user.getBasicProfile().getGivenName())
          setCandidateLastName(user.getBasicProfile().getFamilyName())
      } 
    }, []); // Empty dependency array means this effect runs once after the initial render

    useEffect(() => {
      // url: /home/?job=<jobID>
      // 2b
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("job")){
        const jobIdfromUrl = queryParams.get('job');
        setJobId(jobIdfromUrl);

        // test if jobId is valid, (and get job type from recruiter requirements)
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/toBInterviewData/verifyJobId";
        axios.post(apiUrl, {
          jobId: jobIdfromUrl
        }).then(response =>{
          const jobIdValid = response.data.data;
          if (!jobIdValid){
            setJobIdValid(false);
          }
        }).catch(error => {
          console.error("Error verifying job id:", error);
        });
      }
    }, []);
  
    const handleChange = (event) => {
      setAgreeToS(!agreeToS)
    };
    const handleLinkClick = (event) => {
      // Prevent the default behavior of the link
      event.preventDefault();
      // Trigger the popup
      userAgreement();
    };

    const userAgreement = () => {
      Swal.fire({
          title: "<span style='font-family: Times New Roman;'>MirWork Privacy Policy</span>",
          html: privacy_policy, 
          showCancelButton: true,
          cancelButtonText: "close",
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          animation: false,
          width: 600,
          height: 600,
        });   
    }
    const handleSignIn = async () => {
      try {
        // Sign in with Google
        let auth2 = await loadAuth2(gapi, clientId, '')
        const googleUser = await auth2.signIn();
        const profile = googleUser.getBasicProfile();
    
        setCandidate(profile.getName())
        setCandidateEmail(profile.getEmail())
        setCandidateFirstName(profile.getGivenName())
        setCandidateLastName(profile.getFamilyName())
        setLogin(true);

        console.log('Sign in with Email: ' + profile.getEmail());

        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
        axios.post(apiUrl, {
            email: profile.getEmail(),
            firstName: profile.getGivenName(),
            lastName: profile.getFamilyName(),
          })
            .catch(error => {
              console.error("Error saving message:", error);
            });
      } catch (error) {
        console.error("Not signed in", error);
      }
    };
  
    return (
      <Box align="center" className="homePage">
        <Stack direction="column" spacing = {12} id="homePage-stack">
          <Typography variant="h6" id="homePage-text">
            Hi {candidateFirstName}
          </Typography>
            <Stack direction="column" align="center" spacing = {3}>
              {
                !jobId && 
                <>
                  <Link 
                    href="/interview/?round=hr-round"
                    alignItems="center" 
                    justifyContent="center" 
                    id={(agreeToS)? "homePage-button" : "homePage-button-cantjoin"}
                    onClick = {(e) => {if (!agreeToS) e.preventDefault();}}
                  >
                    HR ROUND
                  </Link>
                  <Link 
                    href="/interview/?round=prod-sense" 
                    alignItems="center" 
                    justifyContent="center" 
                    id={(agreeToS)? "homePage-button" : "homePage-button-cantjoin"}
                    onClick = {(e) => {if (!agreeToS) e.preventDefault();}}
                  >
                    PRODUCT SENSE
                  </Link>
                </>
              }
              {
                jobId &&
                <Link href={`/interview/?round=customized&job=${jobId}`}
                      onClick={(e) => { if (!login || !jobIdValid || !agreeToS) e.preventDefault(); }}
                      alignItems="center" justifyContent="center" 
                      id={(login && jobIdValid && agreeToS)? "homePage-button" : "homePage-button-cantjoin"}>
                  START INTERVIEW
                </Link>
              }
              {
                login ?
                  <Link alignItems="center" justifyContent="center" id="homePage-button-white"
                    onClick = {async (e) => {
                      e.preventDefault();
                      let auth2 = await loadAuth2(gapi, clientId, '');
                      auth2.signOut();
                      setLogin(false);
                      setCandidateFirstName('Candidate');}}>
                    SIGN OUT
                  </Link>
                : 
                  <Link alignItems="center" justifyContent="center" className="gsi-material-button" id={agreeToS?"homePage-button-white":"homePage-button-white-cantjoin"}
                      onClick={(e) => {
                        if (agreeToS) {
                          e.preventDefault();
                          handleSignIn();
                        } }}>
                    <div className="gsi-material-button-state"></div>
                    <div className="gsi-material-button-content-wrapper">
                      <div className="gsi-material-button-icon">
                        <svg version="1.1" viewBox="0 0 48 48" style={{ display: 'block' }}>
                          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span className="gsi-material-button-contents">SIGN IN WITH GOOGLE</span>
                      <span style={{ display: 'none' }}>SIGN IN WITH GOOGLE</span>
                    </div>
                  </Link>
              }
              <FormControlLabel 
                control={<Checkbox checked={agreeToS} onChange={handleChange} name="checkedUserAgree" style={{color: "#8e8e8e"}}/>}
                label={<div id="stt-agreement"> Agree with MirWork <a id="stt-agreement-link" href="#" onClick={handleLinkClick}>Privacy Policy</a></div>}
              />
            </Stack>

        </Stack>
      </Box>
    );
};
  
export default HomePage;