import { useEffect, useState, React } from 'react';
import axios from 'axios';
import "./Feedback.css";
import { postInterviewNotice, unAuthorizedNotice, badIdNotice } from './PostInterviewNotice';
import { gapi, loadAuth2 } from 'gapi-script'
import { Collapse, Typography, Box, Stack, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Chart from 'chart.js/auto';
import { Radar } from "react-chartjs-2";
import Swal from 'sweetalert2'
import {getRadarData} from "./RadarChart"

/*
/feedback/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
*/

const Feedback = () => {

    const [feedback, setFeedback] = useState('');
    const [errorNotice, setErrorNotice] = useState(false);

    const [candidate, setCandidate] = useState('Candidate');
    const [candidateEmail, setCandidateEmail] = useState('');

    const [jobId, setJobId] = useState(null);
    const [round, setRound] = useState("");

    const areasForEval = round === "hr-round"? 6 : 8;
    const [isExpandedTopics, setExpandedTopics] = useState(Array(areasForEval).fill(false));

    let feedbackSections;
    if (round === "hr-round"){
        feedbackSections = [{title: "Experience Fit", startIdx: 1},
                            {title: "Resume Accuracy", startIdx: 4},
                            {title: "Communication Skills", startIdx: 7},
                            {title: "Leadership and Team Management Skills", startIdx: 10},
                            {title: "Problem Solving Skills", startIdx: 13},
                            {title: "Overall Performance", startIdx: 16}]
    } else if (round === "prod-sense"){
        feedbackSections = [{title: "Clarifying Questions", startIdx: 1},
                            {title: "Business Acumen", startIdx: 4},
                            {title: "Product Vision", startIdx: 7},
                            {title: "User Centricity", startIdx: 10},
                            {title: "Passion and Creativity", startIdx: 13},
                            {title: "Ability to Discuss Trade-offs and Possible Errors", startIdx: 16},
                            {title: "Communication", startIdx: 19},
                            {title: "Collaboration", startIdx: 22}]
    }
    
    useEffect(async () => {
        let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
        if (auth2.isSignedIn.get()) {
            let user = auth2.currentUser.get()
            setCandidate(user.getBasicProfile().getGivenName())
            setCandidateEmail(user.getBasicProfile().getEmail())
        }
    }, []);

    useEffect(() => {
        if (candidateEmail){
            fetchChatCompletion(); // setCandidateEmail is asynchronous
        }  
    }, [candidateEmail]);

    useEffect(() => {
        if(round){
            fetchChatCompletion(); 
        }
    }, [round]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("job")){ 
          setJobId(queryParams.get('job'));
          setFeedback("Interview Finished. Thank you!")
        }
        setRound(queryParams.get('round'));
    }, []);

    const fetchChatCompletion = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const chatHistoryId = queryParams.get('id');
        if(!chatHistoryId){
            setErrorNotice(true)
            setFeedback(badIdNotice);
        } else {
            const headers = {
                'Content-Type': 'application/json',
            }
            const url = process.env.REACT_APP_BACKEND_URL + '/api/openai/feedbackGenerationAndStorage';
            axios.post(url, {
                chatHistory: chatHistoryId,
                email: candidateEmail,
                jobId: jobId,
                round: round
            }, { headers })
            .then(response => {
                if (!jobId) {
                    // no feedback provided for real interview
                    handleCompletionResponse(response)
                }
            })
            .catch(error => {
                if (error.response.status === 403){
                    setErrorNotice(true)
                    setFeedback(unAuthorizedNotice)
                }
                console.error(error);
            });
        }
    }

    const handleCompletionResponse = (response) => {
        const data = response.data;
        const content = data.data.reply.content; // an array of string
        setErrorNotice(false);
        setFeedback(content);
        Swal.fire({
            title: `<div class="feedback-questionnaire">What is your impression of our interview process?</div>`,
            showDenyButton: true,
            focusConfirm: false,
            confirmButtonText: `Great !`,
            confirmButtonColor: "#228B22",
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: ``,
            denyButtonAriaLabel: "Thumbs down",
            denyButtonText: `Bad !`,
          })
    }

    const toggleExpand = (topicIndex) => {
        setExpandedTopics(prevState => prevState.map((item, index) => 
            index === topicIndex ? !item : item
        ));
    };

    
    return (
        <Box id="feedback-container">
            {
                (jobId || (feedback && errorNotice)) &&
                <Box id= "feedback-wait">
                    {feedback}
                </Box>
            }
            {
                !jobId && !feedback &&
                <Box id="feedback-wait">
                    {postInterviewNotice}
                </Box>
            }
            {
                !jobId && feedback && !errorNotice &&
                <Box>
                    <Box id="feedback-text-left"> 
                        <Stack direction="column" align="center" spacing = {4} id="feedback-stack">
                        
                        <Typography id="feedback-greatJob">
                            Great job, {candidate}!
                        </Typography>
                        <Box id="feedback-summary">
                            <Table id="feedback-table">
                                <TableBody>
                                    <TableRow>
                                    <TableCell sx={{ borderBottom: "none", maxWidth: "170px" }} id="feedback-tableCellOne">Interview Type</TableCell>
                                    <TableCell sx={{ borderBottom: "none", maxWidth: "170px" }} id="feedback-tableCellTwo">
                                        {round === 'hr-round' && "Round 1 - HR Round"}
                                        {round === 'prod-sense' && "Round 2 - Product Sense"}
                                    </TableCell>
                                    </TableRow >
                                    <TableRow>
                                    <TableCell sx={{ maxWidth: "170px" }} id="feedback-tableCellOne">Status</TableCell>
                                    <TableCell sx={{ maxWidth: "170px" }} id="feedback-tableCellTwo">Completed</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>

                        <Box id="feedback-radar">
                            {<Radar data={getRadarData(feedback, round).data} options={getRadarData(feedback, round).options} />}
                        </Box>
                        </Stack>
                    </Box>
                    <Box id="feedback-text-right">
                        <div className="feedback-text-overview">
                            {feedback[0]}
                        </div>
                        <br />
                        {feedbackSections.map((section, index) => (
                            <div key={index}>
                                <div className="feedback-title">
                                    {section.title}
                                    <div className="feedback-icon" onClick={() => toggleExpand(index)}>
                                        <FontAwesomeIcon icon={isExpandedTopics[index] ? faAngleUp : faAngleDown} />
                                    </div>
                                </div>
                                <div className="feedback-rating">
                                    {feedback[section.startIdx] === "n/a" ? feedback[section.startIdx] : `${feedback[section.startIdx]} / 5`}
                                </div>
                                <Collapse in={isExpandedTopics[index]}>
                                    <div className="feedback-subtitle"> Feedback </div>
                                    <div className="feedback-text"> {feedback[section.startIdx + 1]} </div>
                                    <div className="feedback-subtitle"> How to improve </div>
                                    <div className="feedback-text"> {feedback[section.startIdx + 2]} </div>
                                </Collapse>
                                { index !== feedbackSections.length - 1 && <hr className="feedback-rule"/> }
                            </div>
                        ))}
                    </Box>
                </Box>
            }
        </Box>
    );
}

export default Feedback;