import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faVideo, faVideoSlash, faMicrophoneSlash, faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";
import { Box, Stack, Button, Tooltip } from '@mui/material';
import "./Footer.css";
import { getChatHist } from '../chat_history/speech-to-text.jsx';
import { gapi, loadAuth2 } from 'gapi-script'


const Footer = (props) => {
  const [mic, setMic] = useState(false);
  const [video, setVideo] = useState(false);
  const [cc, setCc] = useState(false);
  // const [isDevMode, setIsDevMode] = useState(false);
  const [candidate, setCandidate] = useState('Candidate');
  const [candidateEmail, setCandidateEmail] = useState('');
  const [jobId, setJobId] = useState(null);
  const [round, setRound] = useState(null);

  useEffect(async () => {
    let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
    if (auth2.isSignedIn.get()) {
        let user = auth2.currentUser.get()
        setCandidate(user.getBasicProfile().getName())
        setCandidateEmail(user.getBasicProfile().getEmail())
    } 
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
      // 2b
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("job")){ 
        setJobId(queryParams.get('job'));
      }
      setRound(queryParams.get('round'));
  }, []);

  const micClick = () => {
    props.onMicClick(!mic);
    setMic(!mic);
  };

  const videoClick = () => {
    props.onVideoClick(!video);
    setVideo(!video);
  };

  const ccClick = () => {
    props.onccClick(!cc);
    setCc(!cc);
  };

  const handleEndButtonClick = () => {
    const fullChatHist = JSON.stringify(getChatHist(), null, 2).replace(/\\n/g, '\n');
    const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/userData/chat";
    axios.post(apiUrl, {
      email: candidateEmail,
      message: fullChatHist,
      promptId: props.interviewPromptId,
      interviewQuestion: props.interviewQuestion,
      jobId: jobId,
    })
      .then(response => {
        
        const queryParamsObj = new URLSearchParams({ id: response.data.data, round: round }); // id: chatID
        if (jobId){
          queryParamsObj.append('job', jobId);
        }
        const queryParams = queryParamsObj.toString();
        window.location.href = `/feedback?${queryParams}`;
      })
      .catch(error => {
        console.error("Error saving message:", error);
      });
  };

  // const devModeClick = () => {
  //   props.onDevModeClick(!isDevMode);
  //   setIsDevMode(!isDevMode);
  // };

  return (
    <Box className="footer">
      <Stack direction="horizontal">
        <Button
          variant="contained"
          size="large"
          id={!mic ? "footer-icons-active" : "footer-icons"}
          onClick={micClick}
        >
          <Tooltip title={mic ? "Mute" : "Unmute"}>
            <FontAwesomeIcon icon={!mic ? faMicrophoneSlash : faMicrophone} />
          </Tooltip>
        </Button>

        <Button
          variant="contained"
          size="large"
          id={"footer-icons" + (!video ? "-active" : "")}
          onClick={videoClick}
        >
          <Tooltip title={video ? "Stop Video" : "Start Video"}>
            <FontAwesomeIcon icon={!video ? faVideoSlash : faVideo} />
          </Tooltip>
        </Button>

        {
          props.hasUserJoined &&

          <Button
            variant="contained"
            size="large"
            id={"footer-icons" + (cc ? "" : "-inactive")}
            onClick={ccClick}
          >
            <Tooltip title={cc ? "Hide History" : "Show History"}>
              <FontAwesomeIcon icon={faClosedCaptioning} />
            </Tooltip>
          </Button>
        }
      </Stack>

      {
        props.hasUserJoined &&
        <Button id="end-button" onClick={handleEndButtonClick}>End</Button>
      }

      {/* <div
        className={"dev-mode-icon " + (!isDevMode ? "active" : "")}
        data-tip={isDevMode ? "Turn off dev mode" : "Turn on dev mode"}
        onClick={devModeClick}
      >
        <FontAwesomeIcon icon={isDevMode ? faBug : faBugSlash} />
      </div> */}
    </Box>
  );
};

export default Footer;