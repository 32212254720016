
const convertScore = (score) => {
    return score && score !== "n/a"? parseFloat(score) : 0;
}

const getRadarData = (feedback, round) => {
    let data;
    const options = {
        layout: {
            padding: {
                left: 36,
                right: 36,
            }
        },
        plugins: {
            legend: {
                display: false,  // This will hide the legend
            }
        },
        scale: {
            min: 0,
            max: 5,
        },
        scales: {
            r: {
                angleLines: {
                    color: '#8f8f8f',
                },
                grid: {
                    color: '#8f8f8f',
                },    
                ticks: {
                    display: false, // Hide the radial scale labels
                    stepSize: 1,
                    beginAtZero: true, 
                    max: 5, 
                    min: 0, 
                },
                pointLabels: {
                    color: '#E5E5E5',
                    font: {
                        size: 12,
                        weight: '600',
                        family: 'Open Sans',
                    },
                    padding: 20,
                }
            }
        }
    };

    if (round === 'hr-round'){
        data = {
            labels: [
                'Experience Fit',
                'Resume Accuracy',
                'Communication Skills',
                ['Leadership and', 'Team Management', 'Skills'],
                ['Problem', 'Solving Skills'],
            ],
            datasets: [{
                data: Array.from({ length: 5 }, (_, i) => convertScore(feedback[1 + i * 3])), //scores
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.3)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: 'rgb(255, 99, 132)',
                pointHoverBackgroundColor: '#E5E5E5',
                pointHoverBorderColor: '#E5E5E5',
                pointHoverRadius: 5.5,
            }]
        };        
    } else if (round === 'prod-sense'){
        data = {
            labels: [
                'Clarifying Questions',
                'Business Acumen',
                'Product Vision',
                'User Centricity',
                ['Passion and', 'Creativity'],
                ['Ability to Discuss', 'Trade-offs and \n\n', 'Possible Errors\n\n'],
                'Communication',
                'Collaboration'
            ],
            datasets: [{
                data: Array.from({ length: 8 }, (_, i) => convertScore(feedback[1 + i * 3])), //scores
                fill: true,
                backgroundColor: 'rgba(255, 99, 132, 0.3)',
                borderColor: 'rgb(255, 99, 132)',
                pointBackgroundColor: 'rgb(255, 99, 132)',
                pointBorderColor: 'rgb(255, 99, 132)',
                pointHoverBackgroundColor: '#E5E5E5',
                pointHoverBorderColor: '#E5E5E5',
                pointHoverRadius: 5.5,
            }]
        };
    }

    return {data: data, options: options};
}


export {getRadarData};