import React from 'react';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';
import TopBar from './components/top_bar/TopBar';
import MainScreen from './components/main_screen/MainScreen';
import Footer from './components/footer/Footer';
import SpeechToText from './components/chat_history/speech-to-text';
import HomePage from './components/home_page/HomePage';
import Feedback from './components/feedback/Feedback';


class MockInterview extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            mic: false,
            video: false,
            cc: false,
            isIntvSpeaking: false,
            isCandSpeaking: false,
            isDevMode: false,
            hasUserJoined: false,
            interviewPromptId: "",
            interviewQuestion: "",
            name: "Candidate",
        }
    }

    onMicClick = (mic) => {
        this.setState({mic: mic});
    }

    onVideoClick = (video) => {
        this.setState({video: video});
    }

    onccClick = (cc) => {
        this.setState({cc: cc});
    }

    onDevModeClick = (isDevMode) => {
        this.setState({isDevMode: isDevMode});
    }

    setIntvSpeaking = (isIntvSpeaking) => {
        this.setState({isIntvSpeaking: isIntvSpeaking});
    }

    setCandSpeaking = (isCandSpeaking) => {
        this.setState({isCandSpeaking: isCandSpeaking});
    }

    onUserJoining = (hasUserJoined) =>{
        this.setState({hasUserJoined: hasUserJoined})
    }

    setInterviewPrompt = (interviewPromptId, interviewQuestion) => {
        this.setState({interviewPromptId: interviewPromptId})
        this.setState({interviewQuestion: interviewQuestion})
    }

    render(){
        return(
            <BrowserRouter>
                <Route exact path="/" render={() => <Redirect to="/home"/>}/>
                
                <Route path="/home" render={(props)=><HomePage {...props}
                                                        name={this.state.name}
                                                        location={this.state.location}/>} />
                
                <Route path="/interview" render={(props)=><TopBar {...props}/>} />

                <Route path="/interview" render={(props)=><MainScreen {...props} 
                                                    isVideoOn={this.state.video}
                                                    isMicOn={this.state.mic}
                                                    isccOn={this.state.cc}
                                                    isIntvSpeaking={this.state.isIntvSpeaking}
                                                    isCandSpeaking={this.state.isCandSpeaking}
                                                    hasUserJoined={this.state.hasUserJoined}/>}/>

                <Route path="/interview" render={() =><SpeechToText 
                                                isMicOn={this.state.mic}
                                                isccOn={this.state.cc}
                                                isDevMode={this.state.isDevMode}
                                                setIntvSpeaking={this.setIntvSpeaking}
                                                setCandSpeaking={this.setCandSpeaking}
                                                onUserJoining={this.onUserJoining}
                                                setInterviewPrompt={this.setInterviewPrompt}/>} />
            
                <Route path="/interview" render={(props)=><Footer {...props} 
                                                    onMicClick={this.onMicClick} 
                                                    onVideoClick={this.onVideoClick} 
                                                    onccClick={this.onccClick}
                                                    onDevModeClick={this.onDevModeClick}
                                                    hasUserJoined={this.state.hasUserJoined}
                                                    interviewPromptId={this.state.interviewPromptId}
                                                    interviewQuestion={this.state.interviewQuestion}/>} />
                
                <Route path="/feedback" render={(props)=><Feedback {...props}/>} />

            </BrowserRouter>
        );
    }
}

export default MockInterview;