export const privacy_policy=`
<style>
div.container {
background-color: #ffffff;
height: 200px; /* Set the height of your div */
overflow: auto; /* Add a scrollbar when content overflows */
}
div.container p {
line-height: 1;
font-family: 'Times New Roman';
font-size: 12px;
font-style: normal;
font-weight: normal;
text-decoration: none;
text-transform: none;
color: #000000;
background-color: #ffffff;
}
</style>

<div class="container">
<p>Introduction</p>
<p>This Privacy Policy applies to all our products, software, and other services (collectively, the "Services") provided by MirWork (referred to as "MirWork", "We", or "Us"). We encourage you to read this MirWork Privacy Policy (the "Policy") thoroughly to understand how we collect and use your personal information and the choices available to you. This Policy outlines:</p>
<p></p>
<p>1. The information we collect and use</p>
<p>2. Our use of cookies</p>
<p>3. Sharing, transferring, and public disclosure of your personal information</p>
<p>4. Protection and storage of your personal information</p>
<p>5. Your management of personal information</p>
<p>6. Children's information protection</p>
<p>7. Intellectual property rights and declarations</p>
<p>8. Policy notices and amendments</p>
<p>9. By using the Services, and clicking "Agree", you consent to our lawful use and protection of your personal information as described in this Policy.</p>
<p></p>
<p>Terms used in this Policy have the same meanings as in the MirWork User Service Agreement. We are committed to handling your personal information appropriately in compliance with applicable laws and regulations.</p>
<p></p>
<p>For inquiries regarding this Policy, please contact us through the information provided on our platform.</p>
<p></p>
<p>1. Information We Collect and Use</p>
<p>At MirWork, we collect and use information to enhance our AI-driven interviewing services. This includes both training and feedback for users and helping companies evaluate users' interview performances.</p>
<p></p>
<p>1.1 Information We Collect:</p>
<p>We may collect, store, and use the following types of personal information:</p>
<p></p>
<p>user Information: This includes name, contact details, resume data, interview responses, voice and video recordings during interviews, and feedback provided.</p>
<p>Company Information: For our corporate clients, we collect contact details, company profiles, job descriptions, and feedback on users.</p>
<p>Usage Data: Information on how both users and companies interact with our services, including log data, device information, and usage patterns.</p>
<p>Interview Data: Responses, analytics, and performance metrics generated during interviews, used for AI training and feedback.</p>
<p></p>
<p>1.2 How We Use the Information:</p>
<p></p>
<p>AI Training and Modeling: We use collected data to train our AI models and algorithms, improving the accuracy and effectiveness of our interviewing services.</p>
<p>Feedback and Evaluation: To provide users with personalized feedback and to assist companies in evaluating interview performances.</p>
<p>Service Improvement: To enhance the usability and functionality of our services.</p>
<p>Communication: To contact users for service-related purposes.</p>
<p></p>
<p>2. Cookies We Use</p>
<p>We use cookies to improve user experience and service functionality. This includes:</p>
<p></p>
<p>Session Cookies: For maintaining user sessions and preferences.</p>
<p>Analytics Cookies: To gather usage statistics for service optimization.</p>
<p>Functional Cookies: Enhancing user experience and personalization.</p>
<p></p>
<p>3. Sharing, Transferring, and Public Disclosure</p>
<p>With Consent: We share personal data with third parties when we have explicit consent from users.</p>
<p>Legal Compliance: If required by law, we may disclose information.</p>
<p>Service Providers: We engage with trusted service providers who process data on our behalf, under strict confidentiality agreements.</p>
<p>Business Transfers: In case of a merger or acquisition, user information may be transferred as part of the business assets.</p>
<p></p>
<p>4. Protection and Storage of Personal Information</p>
<p>Security Measures: We employ industry-standard security measures, including encryption and access controls, to protect user data.</p>
<p>Data Storage: Information is stored on secure servers, with limited access to authorized personnel only.</p>
<p></p>
<p>5. Managing Your Personal Information</p>
<p>Users have the right to access, correct, or delete their personal data. Requests can be made through our user platform or by contacting our support team.</p>
<p></p>
<p>6. Protection of Children’s Information</p>
<p>MirWork does not knowingly collect personal data from children under the age of 16. If such data is collected inadvertently, we take steps to delete it promptly.</p>
<p></p>
<p>7. Intellectual Property Licensing and Declaration</p>
<p>The content and technology used in MirWork's services are the property of MirWork or its licensors. Users are granted a non-exclusive license to use our services in accordance with our terms and policies.</p>
<p></p>
<p>8. Notices and Amendments</p>
<p>Our Privacy Policy may be updated to reflect changes in our practices or legal requirements. Users will be notified of significant changes through our platform. Continued use of our services after changes implies acceptance of the new terms.</p>
<p>Section 9: Contacting Us</p>
<p>For questions, comments, or complaints about this Policy, or to reach out to us for other reasons, please contact:</p>
<p></p>
<p>For software or account-related inquiries or general privacy concerns, you can email us at [support@mirwork.ai], or write to us at [6655 Clark Ave, #34, Newark, CA, 94560].</p>
<p>If our response does not satisfy you, especially regarding personal information handling that affects your rights, you may approach relevant regulatory authorities for complaints or reports.</p>
<p></p>
</div>`