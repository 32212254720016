import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { gapi, loadAuth2 } from 'gapi-script'
import Swal from 'sweetalert2'
import { Avatar, Button, Stack, Typography, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import { createClient, LiveTranscriptionEvents, LiveTranscriptionEvent } from "@deepgram/sdk";
import "./speech-to-text.css";

/*
/interview/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
round: required
*/

var promptJson;
var systemPrompt;
var initialQuestion;
var interviewQuestion;
var promptId;
const chatCompletionHist = [];
var dg_mic;
var dg_socket;
const dgFinalTranscriptHist = [];

var resumeParseResult = "";
const { v4: uuidv4 } = require('uuid');

const updatePrompt = (role, content) => {
    console.log('updating prompt')
    promptJson = {
        conversation: [
            ...promptJson.conversation, 
            {
            role: role,
            content: content,
        }]
    }
}


// timeouts for detecting when the user finishes speaking
var timeOutSeconds = 2500;
var timeOutSecondsAfterJoining = 1000;
var sessionTimeOutSeconds = 8000;
var sessionAliveRetryTimes = 2;
var remainingRetryTimes = 2;
const sessionResumeText = "Interview paused. Please click the button to resume when you are ready."
const sessionExpireNotice = "Sorry I can't hear you. Could you repeat what you said?"
var isPostingNotice = false;

// timeouts for detecting when the AI interviewer finishes speaking
let waitingTimeout;
var aiTimeOutSeconds = 500;
var interviewStartTime = -1.0;
let uuid;


const SpeechToText = ({isMicOn, isccOn, isDevMode, setIntvSpeaking, setCandSpeaking, 
                       onUserJoining, setInterviewPrompt}) => {

    const [hasInterviewStarted, setInterviewStarted] = useState(false);
    const [hasInitialQuestionFinished, setInitialQuestionFinished] = useState(false);
    const [candidate, setCandidate] = useState('Candidate');
    const [resumeUploaded, setResumeUploaded] = useState(false);
    const [isUttering, setIsUttering] = useState(false);
    const [candidateInfo, setCandidateInfo] = useState(null);

    const [jobId, setJobId] = useState(null);
    const [round, setRound] = useState('');

    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [openJDModal, setOpenJDModal] = useState(false);
    const [jdText, setJdText] = useState('');
    const [jd, setJd] = useState('');

    const [dgFinalTranscript, setDgFinalTranscript] = useState("");
    const [dgTempTranscript, setDgTempTranscript] = useState("");
    const [isMicrophoneOn, setMicrophoneOn] = useState(false);
    const isMicrophoneOnRef = useRef(isMicrophoneOn);
    const [isSocketOn, setSocketOn] = useState(false);

    const wsRef = useRef(null);

    useEffect(async() => {
        let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
            if (auth2.isSignedIn.get()) {
                let user = auth2.currentUser.get()
                // console.log(user.getBasicProfile().getName())
                setCandidate(user.getBasicProfile().getName())
                setCandidateInfo(user.getBasicProfile())
            }
        dg_mic = await getMicrophone();
    }, [])

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has("job")){ 
        setJobId(queryParams.get('job'));
      }
      if (!queryParams.has("round")){
          // round is required in url, if not present, reroute to home
          window.location.href = `/`;
      }
      setRound(queryParams.get('round'))
  }, []);

    useEffect(() => {
        setInterviewPrompt(promptId, interviewQuestion);
    }, [promptId, interviewQuestion])
    
    useEffect(() => {
        if(isUttering){
            const mediaSource = new MediaSource();
            let sourceBuffer;
            let audioChunks = [];
            let allDataReceived = false;
            let fullText = '';

            let audio = new Audio();
            let objectURL = URL.createObjectURL(mediaSource);
            audio.src = objectURL;

            mediaSource.addEventListener('sourceopen', () =>{
                if(!sourceBuffer){
                    sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                    sourceBuffer.addEventListener('updateend', () => {
                        if (audioChunks.length > 0 && !sourceBuffer.updating) {
                            sourceBuffer.appendBuffer(audioChunks.shift());
                        }
                    });
                }
            })

            if (wsRef.current) {
                wsRef.current.onmessage = (event) => {
                    if (event.data instanceof Blob) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            audioChunks.push(reader.result);
                            if (sourceBuffer && !sourceBuffer.updating) {
                                sourceBuffer.appendBuffer(audioChunks.shift());
                            }
                        };
                        reader.readAsArrayBuffer(event.data);
                    } else if (typeof event.data === 'string') {
                        if (event.data === "sentence finished") {
                            if (audioChunks.length > 0 && !sourceBuffer.updating) {
                                sourceBuffer.appendBuffer(audioChunks.shift());
                            }
                        } else {
                            // console.log("[INFO] all data received")
                            allDataReceived = true;
                            fullText = event.data;
                            if (!sourceBuffer.updating && audioChunks.length === 0) {
                                mediaSource.endOfStream();
                            }
                        }
                    }
                };
            }

            audio.addEventListener('play', () => {
                setIntvSpeaking(true);
                console.log("[INFO] Interviewer speaking")
                clearTimeout(waitingTimeout);
            });

            audio.addEventListener('ended', () =>{
                console.log("[INFO] Interviewer got all the data for speaking")
            });

            // audio.addEventListener('error', (e) => {
            //     console.error('Error playing audio:', e);
            // });

            audio.addEventListener('waiting', () => {
                console.log('Audio is waiting for data...');
                
                clearTimeout(waitingTimeout);
                waitingTimeout = setTimeout(() => {
                    console.log('Stop waiting');
                    audio.dispatchEvent(new Event('ended'));
                }, aiTimeOutSeconds);
            });

            audio.onended = () => {
                if (allDataReceived && mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                }
                if(allDataReceived){
                    setIntvSpeaking(false); // turn of indicator
                    utterOnEnd(fullText);
                }
            };

            audio.play().catch(error => console.error("Error playing audio:", error));

            return () => {
                if (wsRef.current) {
                    wsRef.current.onmessage = null;
                }
                if (mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                }
                audio.pause();
                audio.src = '';
                URL.revokeObjectURL(objectURL);
            };   
        } 
    }, [isUttering])

    useEffect(() => {
      const canOpenMicrophone = isMicOn && isSocketOn && !isUttering;
    
      if (!isMicrophoneOn && canOpenMicrophone) {
        if(dg_mic && dg_mic.state !== 'recording'){
          // truly turn on microphone
          openMicrophone();
        }
        // set microphone on
        setMicrophoneOn(true);
          
      } else {
        if (isMicrophoneOn && (!isSocketOn || isUttering)) {
          // truly turn off mic
          closeMicrophone();
        }
        if (isMicrophoneOn && !canOpenMicrophone) {
          // set mic off
          setMicrophoneOn(false);
          setCandSpeaking(false);
        }
        if (!isMicrophoneOn && dg_mic && dg_mic.state == 'recording'){
          closeMicrophone();
          setMicrophoneOn(false);
          setCandSpeaking(false);
        }
      }
    }, [isMicOn, isUttering, isSocketOn]);

    useEffect(() => {
      isMicrophoneOnRef.current = isMicrophoneOn;
    }, [isMicrophoneOn]);


    const startInterview = () => {
      setInterviewStarted(true);
      onUserJoining(true);
      if(!wsRef.current){
          uuid = uuidv4();
          const wsUrl = new URL(process.env.REACT_APP_WS_ADDRESS)
          wsUrl.searchParams.append('session-id', uuid)
          wsRef.current = new WebSocket(wsUrl.toString());
      }
      setIsUttering(true);
      const fetchPromptUrl = process.env.REACT_APP_BACKEND_URL + "/api/userData/interviewPrompt";
      axios.post(fetchPromptUrl, {
          round: round,
          jobId: jobId,
      })
      .then(response => {
          initialQuestion = response.data.initialQuestion;
          systemPrompt = response.data.systemPrompt;
          promptId = response.data.promptId;  
          if (round === 'hr-round'){
              systemPrompt = `${systemPrompt}\n\`\`\`\n${resumeParseResult}\n\`\`\``;
              if (jd){
                  console.log("job description added");
                  systemPrompt += `\n\n Here's the job description \n\`\`\`\n${jd}\n\`\`\``
              }
          }
          if (round === 'prod-sense'){
              interviewQuestion = response.data.interviewQuestion;
          }
          
          promptJson = {
              conversation: [{
                  role: 'system',
                  content: systemPrompt
              },{
                  role: 'assistant',
                  content: initialQuestion
              }
          ]}
          interviewStartTime = new Date().getTime();
          setTimeout(() => {
              fetchChatCompletion(true, false);
          }, timeOutSecondsAfterJoining);
      })
      .catch(error => {
          console.error("Error fetching prompt:", error);
      });
    }


    const handleNewUserInput = (userInput) => {
        updatePrompt('user', userInput);
        fetchChatCompletion(false, true);
    }

    const fetchChatCompletion = (isInitialQuestion, textGen) => {
        const dataToSend = {
            conversation: promptJson.conversation,
            isInitialQuestionTag: isInitialQuestion,
            textGen: textGen, // if need to the generate the conversation
            clientuuid: uuid,
        };
        const stringfyData = JSON.stringify(dataToSend, null, 2);
        const headers = {
            'Content-Type': 'application/json',
        }
        const url = process.env.REACT_APP_BACKEND_URL + '/api/openai/chatCompletion';
        console.log('[INFO] Fetching chat completion');
        axios.post(url, stringfyData, { headers })
        .then(response => {
            console.log("[INFO] Fetch chat completion finishes");
        })
        .catch(error => {
            // Handle any errors
            console.error(error);
        });
    }

    const utterOnEnd = async (fullText) => {

        if(hasInitialQuestionFinished){
            chatCompletionHist.push(fullText);
            if (!isPostingNotice){
              updatePrompt('assistant', fullText);
            } else {
              isPostingNotice = false;
            }
        } else {
            setInitialQuestionFinished(true);
        }

        setIsUttering(false);
        await turnOnSocket();
    }

    // deepgram
    const getTempApiKey = async() => {
      const result = await fetch(process.env.REACT_APP_BACKEND_URL + "/api/dgKey");
      const json = await result.json();
      return json.key;
    }

    const getMicrophone = async() => {
      const userMedia = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      return new MediaRecorder(userMedia);
    }

    const openMicrophone = async() => {

      await dg_mic.start(0);
      dg_mic.ondataavailable = (e) => {
        if (isMicrophoneOnRef.current) {
          const data = e.data;
          dg_socket.send(data);
        }
      }
    };

    const closeMicrophone = async() => {
      if(dg_mic) dg_mic.stop();
    }

    const turnOnSocket = async () => {

      const key = await getTempApiKey();

      const dg_client = createClient(key);
      dg_socket = dg_client.listen.live({ model: "nova-2",
                                          smart_format: true,
                                          interim_results: true,
                                          utterance_end_ms: timeOutSeconds,
                                          // vad_events: true,
                                          // endpointing: 30000,
                                        });

      // const dg_interval = setInterval(() => {
      //   console.log("[DG] Sending KeepAlive")
      //   dg_socket.send(JSON.stringify(`{ "type": "KeepAlive" }`));
      // }, socketTimeOutSeconds);

      dg_socket.on(LiveTranscriptionEvents.Open, () => {
        console.log("[DG] connected to websocket");
        let concatenated = "";
        var transcript = "";
        setSocketOn(true);
        
        const dg_finish_interval = setInterval(() => {
          closeMicrophone();
          
          if (remainingRetryTimes == 0){
            showReconnectionPopUp()
            remainingRetryTimes = sessionAliveRetryTimes;
          } else {
            askUserToRepeat()
          }
          clearInterval(dg_finish_interval);
        }, sessionTimeOutSeconds)
        
        dg_socket.on(LiveTranscriptionEvents.Transcript, (data) => {

            transcript = data.channel.alternatives[0].transcript;

            if (transcript !== "") {
              clearInterval(dg_finish_interval);
              remainingRetryTimes = sessionAliveRetryTimes;

              if (!data.is_final){
                setCandSpeaking(true);
                console.log("[DG] interim:", transcript);
                setDgTempTranscript(transcript);
              }
              else{
                concatenated = concatenated + " " + transcript;
                console.log("[DG] final:", concatenated);
                setDgFinalTranscript(concatenated);
                setDgTempTranscript("");
              }
            }
        });

        dg_socket.on(LiveTranscriptionEvents.UtteranceEnd, (data) => {
          console.log("[DG] full:", concatenated);
          userSpeakingOnEnd(concatenated);
          concatenated = "";
          setCandSpeaking(false);
        })

        dg_socket.on(LiveTranscriptionEvents.Error, (e) => console.error(e));

        dg_socket.on(LiveTranscriptionEvents.Close, (e) => {
          console.log("[DG] closed", e.reason);
          if(concatenated || transcript){
            // if the socket closes before UtteranceEnd
            userSpeakingOnEnd(concatenated? concatenated : transcript);
            concatenated = "";
            transcript = "";
          }
          setSocketOn(false);
          setCandSpeaking(false);
        });
      });
    }

    const askUserToRepeat = () => {
      updatePrompt('user', "");
      dgFinalTranscriptHist.push("");
      updatePrompt('assistant', sessionExpireNotice);
      fetchChatCompletion(false, false);
      isPostingNotice = true;
      setIsUttering(true);
      remainingRetryTimes -= 1;
    }
    
    const showReconnectionPopUp = () => {
      Swal.fire({
        title: `<div class="stt-pause">`+sessionResumeText,
        // text: "You won't be able to revert this!",
        confirmButtonText: "Resume",
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          turnOnSocket();
        }
      });
    }

    const userSpeakingOnEnd = (concatenated) => {
      dgFinalTranscriptHist.push(concatenated);
      handleNewUserInput(concatenated);
      setIsUttering(true);
      dg_socket.finish();
      setDgFinalTranscript("");
      setDgTempTranscript("");
    }

    const handleResumeUpload = async (event) => {
        setLoading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        console.log("Resume info: ", formData.get('file'))
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/openai/resume";
        try {
            const response = await axios.post(apiUrl, formData, {
                headers: {},
            });
            setResumeUploaded(true)
            setFileName(file.name);
            setLoading(false);
            resumeParseResult = response.data.data.reply;
        } catch (error) {
            console.error("Error uploading file:", error);
            setLoading(false);
        }
    }

    const handleOpenJDModal = () => {
        setJdText(jd);
        setOpenJDModal(true);
    };

    const handleCloseJDModal = () => {
        setOpenJDModal(false);
    };

    const handleSaveJD = () => {
        setJd(jdText);
        handleCloseJDModal();
    };

    return (
      <Box id="mainScreen-content">
      {   
        !hasInterviewStarted &&
        <Box id="stt-joinMeeting">
          <Stack spacing = {4}>
            <Typography id="stt-text"> 
              Ready to join? 
            </Typography>
              {
                round === 'hr-round' &&
                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                  <Button id="stt-button" component="label" sx={{ flex: 1 }}>
                    {loading ? <CircularProgress size={24} /> : (resumeUploaded ? <span className="filename-display">{fileName}</span> : 'Upload Resume')}
                    <input type="file" accept=".pdf" hidden onChange={handleResumeUpload} />
                  </Button>
                  <Button id="stt-button" onClick={handleOpenJDModal} sx={{ flex: 1 }}>
                    Add JD
                  </Button>
                  <Dialog open={openJDModal} onClose={handleCloseJDModal} fullWidth maxWidth="md">
                    <DialogContent>
                      <DialogContentText id="stt-dialog">Please add your job description</DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Job Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={10}
                        value={jdText}
                        onChange={(e) => setJdText(e.target.value)}
                        InputLabelProps={{
                            id: 'stt-dialog'
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button id="stt-dialog-button" onClick={handleSaveJD}>Save</Button>
                      <Button id="stt-dialog-button" onClick={handleCloseJDModal}>Cancel</Button>
                      </DialogActions>
                  </Dialog>
                </Stack>
              }
            
            <Button id={(round === 'hr-round' && !resumeUploaded)? "stt-button-cantjoin":"stt-button"} 
                    disabled={(round === 'hr-round' && !resumeUploaded)} onClick={startInterview} sx={{ width: '100%' }}>
              JOIN MEETING
            </Button>
          </Stack>
        </Box>
      }
            
      {
        isccOn && hasInterviewStarted &&
        <Box id="stt-chatHistory-container">
          <Box id="stt-chatHistory-content">
            <div id="stt-flex-content">
              <Avatar id="stt-intv-avatar">INTV</Avatar>
              <p>
                {hasInitialQuestionFinished?  `Interviewer: ${initialQuestion}` : `Interviewer: ...`}
              </p>
            </div>
              {/* { isDevMode && <p> Live transcript: {transcript} </p> } */}
              {/* { isDevMode && <p> Interim transcript: {interimTranscript} </p> } */}
              {
                dgFinalTranscriptHist.map((item, index) => (
                  <div key={index}>
                    {item && 
                      <div id="stt-flex-content">
                        <p className="stt-candidate-content"> 
                          {item && 
                          `${candidate}` + `:` +  ` ${item} `}
                        </p>
                        <Avatar id="stt-cand-avatar">CAND</Avatar>
                      </div>
                    }
                    <div id="stt-flex-content">
                      <Avatar id="stt-intv-avatar">INTV</Avatar>
                        <p>
                          {`Interviewer: ${chatCompletionHist.length > index ? chatCompletionHist[index] : " ..."}`}
                        </p>
                    </div>
                  </div>
                ))
              }
              {
                (dgTempTranscript.length > 0 || dgFinalTranscript.length > 0) && 
                (chatCompletionHist.length >= dgFinalTranscriptHist.length) &&
                //if the candidate is speaking and if the MIR has responded the previous round of conversation
                <div>
                  <div id="stt-flex-content">
                    <p className="stt-candidate-content">{candidate}: {dgFinalTranscript + '\n' + dgTempTranscript}</p>
                    <Avatar id="stt-cand-avatar">CAND</Avatar>
                  </div>
                </div>
              }
          </Box>
        </Box>
      }
      </Box>
    );
}

export function getChatHist(){
    return promptJson.conversation.slice(1); // to exclude the system prompt
}
export default SpeechToText;