import React, { useState, useEffect } from "react";
import { gapi, loadAuth2 } from 'gapi-script'
import { Toolbar, AppBar, Typography, IconButton, Avatar } from '@mui/material';
import './TopBar.css';


const TopBar = () => {
    const [candidate, setCandidate] = useState('Candidate');
    const [candidatePhoto, setCandidatePhoto] = useState('');
    const [round, setRound] = useState('')

    useEffect(async () => {
        let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
        if (auth2.isSignedIn.get()) {
            let user = auth2.currentUser.get()
            console.log(user.getBasicProfile().getName())
            setCandidate(user.getBasicProfile().getName())
            setCandidatePhoto(user.getBasicProfile().getImageUrl())
        } 
    }, []);

    useEffect(() => {
      // 2b
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("round")){ 
          setRound(queryParams.get('round'));
        }
    }, []);
    
    return (
      <AppBar id="topBar-appBar">
        <Toolbar>
          <Typography id="topBar-text">
            {round == "hr-round" && "HR Round Interview"}
            {round == "prod-sense" && "Product Sense Interview"}
          </Typography>
                             
          {
            candidatePhoto &&
            <IconButton id="topBar-avatar-iconbutton">
              <Avatar src={candidatePhoto} id="topBar-avatar"/>
            </IconButton>
          }
        </Toolbar>
      </AppBar>
    );
};

export default TopBar;