import React, { useRef, useState, useEffect } from 'react';
import { Typography, Card, CardMedia, Box, Avatar } from '@mui/material';
import './MainScreen.css';
import { gapi, loadAuth2 } from 'gapi-script'

/*
/interview/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
*/

const MainScreen = (props) => {
  const videoRef = useRef(null);
  const [candidate, setCandidate] = useState('Candidate');
  const [candidateImg, setCandidateImg] = useState('');

  useEffect(async () => {
    if (props.isVideoOn) {
      turnVideoOn();
    } else {
      turnVideoOff();
    }

    let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
    if (auth2.isSignedIn.get()) {
        let user = auth2.currentUser.get()
        setCandidate(user.getBasicProfile().getName())
        setCandidateImg(user.getBasicProfile().getImageUrl())
    }
  }, [props.isVideoOn]);

  const turnVideoOn = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 16 / 9} })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch(error => {
          console.error('Error accessing the camera', error);
        });
    }
  };

  const turnVideoOff = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const videoTracks = videoRef.current.srcObject.getVideoTracks();
      if (videoTracks.length > 0) {
        videoTracks[0].stop();
      }
    }
  };

  return (
    <Box id="mainScreen">
      <Box id="mainScreen-content">
        {
          props.isVideoOn &&
          <Box id={`mainScreen-cand${props.hasUserJoined ? "-joined" : "-notjoined"}-videoon${props.isccOn ? "-ccon" : "-ccoff"}`}>
            <Card elevation={0}
                  className={`mainScreen-indicator-${props.isCandSpeaking?"on" : "off"}`}
                  id={`mainScreen${props.hasUserJoined ? "-joined" : "-notjoined"}${props.isccOn ? "-ccon" : "-ccoff"}-cardMedia`}>
              <CardMedia component="video" ref={videoRef} autoPlay playsInline/>
              <Box id="mainScreen-nameTagBox">
                <Typography id="mainScreen-nameTagText">{candidate}</Typography>
              </Box>
            </Card>
          </Box>
        }
        {
          !props.isVideoOn && 
          <Box id={`mainScreen-cand${props.hasUserJoined ? "-joined" : "-notjoined"}-videooff${props.isccOn ? "-ccon" : "-ccoff"}`}>
            <Box
                 className={`mainScreen-indicator-${props.isCandSpeaking?"on" : "off"}`}
                 id={`mainScreen${props.hasUserJoined ? "-joined" : "-notjoined"}${props.isccOn ? "-ccon" : "-ccoff"}-videoOffBox`}>
              {
                candidateImg?
                <Avatar src={candidateImg} id="mainScreen-cand-avatar"/> :
                <Avatar id="mainScreen-cand-avatar"> CAND </Avatar>
              }
              <Box id="mainScreen-nameTagBox">
                <Typography id="mainScreen-nameTagText">{candidate}</Typography>
              </Box>
            </Box>
          </Box>
        }
        {
          props.isccOn && props.hasUserJoined &&
          <Box id={`mainScreen-intv-ccon`}>
            <Box
                 className={`mainScreen-indicator-${props.isIntvSpeaking?"on" : "off"}`}
                 id={`mainScreen-joined-ccon-videoOffBox`}>
              <Avatar id="mainScreen-intv-avatar"> INTV </Avatar>
              <Box id="mainScreen-nameTagBox">
                <Typography id="mainScreen-nameTagText">Interviewer</Typography>
              </Box>
            </Box>
          </Box>
        }
        {
          !props.isccOn && props.hasUserJoined &&
          <Box id={`mainScreen-intv-ccoff`}>
            <Box
                 className={`mainScreen-indicator-${props.isIntvSpeaking?"on" : "off"}`}
                 id={`mainScreen-joined-ccoff-videoOffBox`}>
              <Avatar id="mainScreen-intv-avatar"> INTV </Avatar>
              <Box id="mainScreen-nameTagBox">
                <Typography id="mainScreen-nameTagText">Interviewer</Typography>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default MainScreen;
